.thumbnailClass{
    border-radius: 5px;
   
}
.originalImage{
    width: 100%;
    height: 600px !important;
    border-radius: 10px;
}
.originalImage_mobile{
    width: 100%;
    height: 200px !important;
    border-radius: 10px;
}

.image_gallery_product{
    width: 97.5%;
    margin-left: 1.25%;
   
}