.section_background{
    width: 100%;
    height: 650px;
    background-image: url('../../images/category.avif') ;
    background-position: center;
    background-size: 100%  100%;
    background-repeat: no-repeat;
    position: relative;
}

.section_transparent{
    width: 100%;
    height: 650px;
    position: absolute;
    top:120px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
  
}
.breadcrum_parent_menu{
    color: #2ab0a7 !important;
    text-decoration: none;
    font-size: 16px;
     font-family: 'Poppins';
     font-weight: 500;
}
.breadcrum_active{
    color: #FFFFFF !important;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 500;
}